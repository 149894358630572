import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Output } from '@angular/core';
import {
  catchError,
  finalize,
  tap,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthToken } from '../providers/auth.provider';
import { AuthService } from './auth.service';
import { LoadingBarService } from '@ngx-loading-bar/core';

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}
export type RequestOptions = {
  headers?: HttpHeaders;
  observe?: 'response'; //'body' | 'events' | 'response',
  params?: HttpParams | { [param: string]: string | string[] };
  reportProgress?: boolean;
  responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  withCredentials?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class BaseHttpService {
  constructor(
    private http: HttpClient,
    @Inject(AuthToken) private auth: AuthService,
    private loadingBar: LoadingBarService   
  ) {
    if (localStorage.getItem(`peakauth:code`) == null) {
      return;
    }
  }

  public isLoadingBarHttp: boolean = true;
  public baseUri = `${environment.apiUri}/api`;

  request(
    method: HttpMethod,
    url: string,
    body: any,
    options?: RequestOptions
  ) {
    let token = localStorage.getItem(`peakauth:code`); //this.peakauth.client.resolver.code()
    token = token!.replace(/^"(.+(?="$))"$/, '$1');

    let httpOptions: any;
    if (typeof url === 'string') {
      if (token) {
        httpOptions = {
          headers: new HttpHeaders({
            //'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }),
        };
      } else {
        httpOptions.headers.delete('Authorization');
        this.auth.login();
      }
    }

    return this.http
      .request(method, url, {
        body: body,
        observe: options?.observe ?? 'body',
        headers: options?.headers ?? httpOptions.headers,
        params: options?.params,
        responseType: options?.responseType ?? 'json',
        reportProgress: options?.reportProgress ?? false,
        withCredentials: options?.withCredentials == true,
      })      
  }
  
  public get<T>(url: string, options?: RequestOptions) {
    this.loadingShow();
    return this.request(HttpMethod.Get, url, options).pipe(
      //tap(() => this.loadingShow()),
      finalize(() => this.loadingHide()),
      catchError((ex: any) => {
        this.loadingHide();
        return this.handleError(ex);
      })
    );
  }
  public post<T = any>(url: string, body?: any, options?: RequestOptions) {
    return this.request(HttpMethod.Post, url, body, options).pipe()    
  }
  public put<T = any>(url: string, options?: RequestOptions) {
    return this.request(HttpMethod.Put, url, options);
  }
  public delete<T = any>(url: string, options?: RequestOptions) {
    return this.request(HttpMethod.Delete, url, options);
  }
  public patch<T = any>(url: string, options?: RequestOptions) {
    return this.request(HttpMethod.Patch, url, options);
  }

  loadingShow() {      
    if (this.isLoadingBarHttp) {
      this.loadingBar.useRef().start();
    }
  }

 loadingHide() { 
    if (this.isLoadingBarHttp) {
      this.loadingBar.useRef().complete();
      this.loadingBar.useRef().stop();
    }
  } 

  public handleError(error: any) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.log(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }

    // Return an observable with a user-facing error message.
    //return throwError(() => new HttpErrorResponse(error));
    return throwError(() => {
      return error;
    });
  }
}
