import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { AdvanceReportModel } from '../../models/reports/advance-report.model';


@Injectable({
  providedIn: 'root',
})
export class AdvanceReportService {
  constructor(private base: BaseHttpService) {}
  apiUrl = `${this.base.baseUri}/Report`;

  getByTenantId(tenantId: string) {
    return this.base.get<AdvanceReportModel[]>(
      `${this.apiUrl}/GetAdvanceByTenantId/${tenantId}`
    );
  };

  getAdvanceByCondition(model: any) {    
    return this.base.get<AdvanceReportModel[]>(
      `${this.apiUrl}/GetAdvanceByCondition?tenantId=${model.TenantId}&&startDate=${model.StartDate??""}&&endDate=${model.EndDate??""}&&userId=${model.UserId??""}&&flowStateId=${model.FlowStateId??""}`
    );
  }
}
