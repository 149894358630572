import { Injectable } from '@angular/core';
import { ExpenseTypeModel } from '../../models/expense-types/expense-type.model';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class ExpenseTypeService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/ExpenseType`

  getByTenantId(tenantId: string) {    
    return this.base.get<ExpenseTypeModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}`)
  };

  add(model: any): Observable<ExpenseTypeModel> {
    return this.base.post(`${this.apiUrl}/Create`, model)
  };

  update(model: any): Observable<ExpenseTypeModel> {
    return this.base.put(`${this.apiUrl}/Update`, model)
  };

  remove(id: string) {
    return this.base.delete(`${this.apiUrl}/DeleteById/${id}`)
  };

}
