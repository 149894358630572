import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { FlowStateModel } from '../../models/flow-states/flow-state.model';

@Injectable({
  providedIn: 'root'
})
export class FlowStateService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/FlowState`

  getAll() {    
    return this.base.get<FlowStateModel[]>(`${this.apiUrl}/GetAll`)    
  };
}
