import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { ExpenseTotalModel } from '../../models/expenses/expense-total.model';

@Injectable({
  providedIn: 'root'
})
export class ExpenseDashboardService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/ExpenseDashboard`

  getTotalExpense(tenantId:string,userId:string){
    return this.base.get<ExpenseTotalModel[]>(`${this.apiUrl}/GetTotalExpense/${tenantId}/${userId}`)    
  };

}
