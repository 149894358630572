import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { AdvanceTotalModel } from '../../models/advances/advance-total.model';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AdvanceDashboardService{
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/AdvanceDashboard`

  getTotalAdvance(tenantId:string,userId:string){
    let headers = new HttpHeaders();
    headers  = headers.append('responseType', 'json')
    return this.base.get<AdvanceTotalModel[]>(`${this.apiUrl}/GetTotalAdvance/${tenantId}/${userId}`)    
  }

}
