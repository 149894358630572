import { Injectable } from '@angular/core';
import { UserModel } from '../models/user.model';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/User`

  getById(id: string) {    
    return this.base.get<UserModel[]>(`${this.apiUrl}/GetById?id=${id}`)
  };

}
