import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthService } from "./services/auth.service";
import { AuthProvider } from "./providers/auth.provider";


function appInitializer(authService: AuthService) {
  return () => {
    return new Promise( async (resolve: any) => {        
     authService.login().then((response)=>{
      setTimeout(() => {        
        resolve();
      }, 2000);     
     })     
    });
  }  
}

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule   
  ],
  providers: [
    AuthService,
    AuthProvider,
    //TokenHelper,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService]
    }        
  ],
  exports: [

  ]
})


export class AuthModule {

}