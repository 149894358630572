import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { ConfirmModel, ExpenseModel, RejectModel } from '../../models/expenses/expense.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExpenseService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/Expense`

  getAll() {
    return this.base.get<ExpenseModel[]>(`${this.apiUrl}/GetAll`)  
  };


  getByUserId(tenantId:string,userId:string) {
    return this.base.get<ExpenseModel[]>(`${this.apiUrl}/GetByUserId/${tenantId}/${userId}`)   
  }

  getByTenantId(tenantId:string,userId:string) {   
    return this.base.get<ExpenseModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}/${userId}`)   
  }

  getPendingByTenantId(tenantId:string){    
    return this.base.get<ExpenseModel[]>(`${this.apiUrl}/GetPendingByTenantId/${tenantId}`)   
  }

  add(model: FormData): Observable<ExpenseModel> {
    return this.base.post(`${this.apiUrl}/Create`,model)    
  };

  remove(id: string) {
    return this.base.delete(`${this.apiUrl}/DeleteById/${id}`)
  };    

  confirm(model: ConfirmModel): Observable<any> {
    return this.base.post(`${this.apiUrl}/Confirm`, model)     
  };

  reject(model: RejectModel): Observable<any> {
    return this.base.post(`${this.apiUrl}/Reject`, model)    
  };

  getPdfById(id: string) {
    return this.base.get(`${this.apiUrl}/GetPdfById/${id}`)     
  };

}


