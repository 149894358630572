import {Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { AdvanceDetailModel } from '../../models/advances/advance-detail.model';

@Injectable({
  providedIn: 'root'
})
export class AdvanceHistoryService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/AdvanceHistory`

  getDetailByTenantId(advanceId:string){    
    return this.base.get<AdvanceDetailModel[]>(`${this.apiUrl}/GetDetailByTenantId/${advanceId}`)  
  }
}
