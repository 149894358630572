
import { Injectable } from '@angular/core';
import { UserModel } from 'src/app/shared/modules/auth/models/user.model';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/User`


  getAll() {    
    return this.base.get<UserModel[]>(`${this.apiUrl}/GetAll`)    
  };

  getByTenantId(tenantId:string){    
    return this.base.get<UserModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}`)   
  }

  getDepartmentGroup(tenantId:string){
    return this.base.get<UserModel[]>(`${this.apiUrl}/GetDepartmentGroup/${tenantId}`)  
  }

}