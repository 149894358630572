import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AdvanceService } from 'src/app/main/services/advances/advance.service';
import { ExpenseService } from 'src/app/main/services/expenses/expense.service';
import { AdvanceTypeService } from './advance-types/advance-type.service';
import { ApiAdminServicesComponent } from './api-admin.services';
import { CurrencyService } from './currencies/currency.service';
import { DefaultCurrencyService } from './default-currencies/default-currency.service';
import { ExpenseTypeService } from './expense-types/expense-type.service';
import { FlowStateService } from './flowStates/flow-state.service';
import { RoleService } from './roles/role.service';
import { AdvanceUserManagerService } from './user-managers/advance-user-manager.service';
import { ExpenseUserManagerService } from './user-managers/expense-user-manager.service';
import { UserRoleService } from './user-roles/user-role.service';
import { UserService } from './users/user.service';
import { ExportSettingService } from './export-settings/export-setting.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule, HttpClientJsonpModule],
  providers: [
    ApiAdminServicesComponent,
    UserService,
    UserRoleService,
    RoleService,
    CurrencyService,
    FlowStateService,
    AdvanceUserManagerService,
    AdvanceTypeService,

    ExpenseUserManagerService,
    ExpenseTypeService,
    DefaultCurrencyService,
    AdvanceService,
    ExpenseService,
    ExportSettingService,
  ],
})
export class ApiAdminModule {}
