import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { UserRoleModel } from '../../models/user-roles/user-role.model';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/UserRole`

  getAll() {    
    return this.base.get<UserRoleModel[]>(`${this.apiUrl}/GetAll`)    
  };

  getByTenantId(tenantId: string) {
    return this.base.get<UserRoleModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}`)     
  }

  add(model: any) {
    return this.base.post(`${this.apiUrl}/Create`, model)   
  };

  update(model: any) {
    return this.base.put(`${this.apiUrl}/Update`, model)     
  };

  remove(id: string) {
    return this.base.delete(`${this.apiUrl}/DeleteById/${id}`)     
  };

}
