import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { AdvanceUserManagerModel } from '../../models/user-managers/advance-user-manager.model';

@Injectable({
  providedIn: 'root'
})
export class AdvanceUserManagerService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/AdvanceUserManager`

  getAll() {    
    return this.base.get<AdvanceUserManagerModel[]>(`${this.apiUrl}/GetAll`)     
  };

  getByTenantId(tenantId:string){
    return this.base.get<AdvanceUserManagerModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}`) 
  };

  getByUserId(userId:string){
    return this.base.get<AdvanceUserManagerModel[]>(`${this.apiUrl}/GetByUserId/${userId}`)   
  };

  add(model: any): Observable<AdvanceUserManagerModel> {
    return this.base.post(`${this.apiUrl}/Create`, model)     
  };

  update(model: any): Observable<AdvanceUserManagerModel> {
    return this.base.post(`${this.apiUrl}/UpdateById`, model)    
  };

  remove(id: string) {
    return this.base.delete(`${this.apiUrl}/DeleteById/${id}`)    
  };

}
