import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { AdvanceTypeModel } from '../../models/advance-types/advance-type.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdvanceTypeService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/AdvanceType`

  getByTenantId(tenantId:string) { 
    return this.base.get<AdvanceTypeModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}`)     
  };

  add(model: any): Observable<AdvanceTypeModel> {
    return this.base.post(`${this.apiUrl}/Create`, model)    
  };

  update(model: any): Observable<AdvanceTypeModel> {
    return this.base.put(`${this.apiUrl}/Update`, model)    
  };

  remove(id: string) {
    return this.base.delete(`${this.apiUrl}/DeleteById/${id}`)     
  };
}

