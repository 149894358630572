import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { CurrencyModel } from '../../models/currencies/currency.model';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/Currency`

  getAll() {    
    return this.base.get<CurrencyModel[]>(`${this.apiUrl}/GetAll`)     
  };

  getByTenantId(tenantId: string) {
    return this.base.get<CurrencyModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}`)  
  };

  add(model: any) {
    return this.base.post(`${this.apiUrl}/Create`, model)    
  };

  update(model: any) {
    return this.base.put(`${this.apiUrl}/Update`, model)    
  };

  remove(id: string) {
    return this.base.delete(`${this.apiUrl}/DeleteById/${id}`)    
  };
}

