// TR
export const locale = {
  lang: 'tr',
  data: {
    TRANSLATOR: {
      SELECT: 'Dilinizi seçiniz',
      LANGUAGE: 'Dil'
    },
    MENU: {
      DASHBOARD: 'Ana Sayfa',
      ADVANCE:'Avans',
      EXPENSE:'Masraf',
      MANAGEMENTS: {
        MANAGEMENT: 'Yönetim',
        USERS: {
          USERS: 'Kullanıcılar',
          CHILD: {
            USERLIST: 'Kullanıcı Listesi',
            USERROLE: 'Kullanıcı Rol Listesi',
            USERROLEDEFINITION:'Kullanıcı Rol Tanım',
            USERAPPROVAL: 'Kullanıcı Onay Süreçleri'
          }
        },
        APPROVALPROCESSES: {
          APPROVALPROCESSES: 'Onay Süreçleri',
          CHILD: {
            ADVANCE: 'Avans Onay Süreci',
            EXPENSE: 'Masraf Onay Süreci'            
          }
        },
        GENERAL: {
          GENERAL: 'Genel',
          CHILD: {
            CURRENCY: 'Para Birimi',
            FLOWSTATE: 'Akışlar',
            ADVANCETYPE: 'Avans Tipi',
            EXPENSETYPE: 'Masraf Tipi'
          }
        }       
      },
      REPORTS:{
        REPORTS:'Raporlar',
        ADVANCE:'Avans',
        EXPENSE:'Masraf',
        CHILD:{
          ADVANCES:'Avans Raporları',
          EXPENSES:'Masraf Raporları'
        }
      }
    },
    GENERAL: {
      Save:'Kaydet',
      Close:'Kapat',
      Yes:'Evet',
      No:'Hayır',
      Choose: 'Seçiniz..',
      Months: 'Aylar',
      Days: 'Günler..',
      RegistrationFailed: 'Kayıt Başarısız..!',
      RegistrationWarningInfo: 'Lütfen Gerekli Alanları Doldurunuz..',
      ManagerWarningInfo: 'Kullanıcıya En Az Bir Yönetici Atayınız..',
      LoadingFailed: 'Data Yüklenemedi..!',
      LoadingWait: 'Yükleniyor..',
      TotalAmount: 'Toplam Tutar',
      New: 'Yeni',
      Import: 'İçe Aktar',
      RegistrationSuccessInfo: 'Kayıt eklendi',
      UpdateSuccessInfo: 'Kayıt güncellendi',
      Successful: 'Başarılı',
      DeleteWarningInfo: 'Silmek istediğine emin misin?',
      DeleteSuccessfulInfo: 'Silindi',
      DeleteRequiredInfo: 'Silinecek satırdaki Id yi belirtin',
      DublicateRecordName: 'İsminde kayıt vardır.',
      NoRecordsFound:'Kayıt bulunamadı',
      Cash:'Peşin',
      Installment:'Taksit',
      Max500Character:'En fazla 500 karakter',
      MinInstallmentCount:'En az 1 taksit giriniz',
      UserIdRequired:'User Id Boş Bırakılamaz',
      Accepted:'Onaylandı',
      Waiting:'Beklemede',
      Cancalled:'İptal Edildi',     
      Rejected:'Reddedildi',
      CreatedBy: 'Oluşturan',
      CreatedDate: 'Oluşturma Tarihi',    
      SearchFilter:'Ara...',  
      MaximumTwoRecordInfo: 'En fazla 2 Yönetici Seçebilirsiniz..',
      ApproveInfo: 'Kayıt Onaylandı',
      RejectInfo:'Kayıt Reddedildi',
      Approve:'Onayla',
      Reject:'Reddet',
      Delete:'Sil',
      Cancel:'İptal Et',
      Print:'Yazdır',
      ConfirmSuccessInfo:'Onaylama İşlemi Başarılı',
      ConfirmWarningInfo:'Onaylanacak Akış Bulunamadı',
      RejectSuccessInfo:'Reddetme İşlemi Başarılı',
      RejectWarningInfo:'Reddedilecek Akış Bulunamadı',
      Advance:'Avans',  
      Expense:'Masraf',    
      Advances:'Avanslar',
      Expenses:'Masraflar',  
      CameraPermissions:'Kamera İzinleri İste',
      ScanImageFile:'Görüntü Dosyası Tara',
      AddQrCodeDocuments:'QR Kod İle Döküman Ekle',
      About:'Hakkında',
      Contact:'Temas',
      Purchase:'Satın Alma',
      SelectUser: 'Kullanıcı Seç',
      SelectItem: 'Öğeyi Seç..',
      Actions:'Aksiyon',
      UserManagerPendingInfo:'Adlı Kullanıcının Bekleyen Akışı Vardır. Yönetici Değişikliği Yapabilmeniz İçin Bekleyen Akışlarını Sonlandırınız..',
      AdvanceManagerApprovalInfo:'Avans başvurusu için en az 1 yöneticiniz olmalıdır...',
      ExpenseManagerApprovalInfo:'Masraf başvurusu için en az 1 yöneticiniz olmalıdır...',
      Detail:'Detay',
      AdvanceConfirmInfo:'Onayımı Bekleyen Avanslar',
      AdvanceAndExpense:'Avans Ve Masraf',
      ChooseColumns:'Sütunları Seç',
      ColumnsSelected:'Sütun Seçildi',
      DetailSearch: 'Detaylı Arama...', 
      Search:'Ara',
      Question:'Sorgula',
      Date:'Tarih',
      StartDate:'Başlangıç Tarihi',
      EndDate:'Bitiş Tarihi',
      EmployeeName:'Personel Adı',
      Employee:'Personel',
      AdvanceStatus:'Avans Durumu',
      ExpenseStatus:'Masraf Durumu'
    },
    PAGES: {
      USERLIST: {
        Id: 'Id',
        TenantId: 'Tenant Id',
        Name: 'Adı',
        Surname: 'Soyadı',
        DisplayName: 'Görünen Ad',
        MailAddress: 'Mail Adres',
        Department: 'Departman',
        JobTitle: 'Ünvanı',
        JoinDate: 'İşe Giriş Tarihi',
        IsDeleted: 'Silindi Mi'
      },
      USERROLE: {
        UserRoleId: 'Id',
        UserName: 'Kullanıcı',
        RoleName: 'Role',
        CreatedBy: 'Oluşturan',
        CreatedDate: 'Oluşturma Tarihi',
        AssignRole: 'Rol Ata',
        UpdateRole: 'Rol Güncelle'
      },
      FLOWSTATE: {
        Name: 'Adı',
        Description: 'Açıklama',
      },
      CURRENCY: {
        Code: 'Kod',
        Name: 'Adı',
        CurrencyType: 'Para Birimi',
        AddCurrencyType: 'Para Birimi Tanımla',
        UpdateCurrencyType: 'Para Birimi Güncelle'
      },
      ADVANCETYPE: {
        Name: 'Adı',
        MaximumPrice: 'Maksimum Tutar',
        AddAdvanceType: 'Avans Türü Tanımla',
        UpdateAdvanceType: 'Avans Türü Güncelle',
        Unlimited:'Limitsiz'
      },
      ADVANCE: {        
        Id: 'Avans Id',
        AdvanceDate: 'Avans Tarihi',
        PaymentType: 'Ödeme Tipi',
        Description: 'Açıklama',
        InstallmentCount: 'Taksit Adedi',
        AdvanceAmount: 'Avans Tutarı',
        UserName: 'Personel Adı',
        AdvanceTypeName: 'Avans Türü',
        FlowStateName: 'Durumu',
        CurrencyCode: 'Para Birimi',
        AddAdvance:'Avans Tanımla',
        UpdateAdvance: 'Avans Güncelle',
        AdvanceTypeChoose:'Avans Türü Seç',
        PaymentTypeChoose:'Ödeme Türü Seç',
        TotalAdvance:'Toplam Avans',
        TotalAdvanceAmount:'Toplam Alınan Avans Tutarı',
        ApprovingCancelling:'Onaylayan/İptal Eden',
        AdvanceDetail:'Avans Detayı',
        TransactionDate:'İşlem Tarihi'
      },
      USERMANAGER:{
        AssingManagers:'Kullanıcılara Yönetici Ata',
        UpdatingManagers:'Onay Süreci Güncelle',
        AppointedManagers:'Atanacak Yöneticiler',
        AssignedManagers:'Atanmış Yöneticiler',
        Users:'Kullanıcılar',
        Managers:'Yöneticiler',
        UserDisplayName:'Kullanıcı Adı',
        UserMailAddress:'Kullanıcı Mail Adresi',
        UserDepartment:'Kullanıcı Departman',
        ManagerDisplayName:'Yönetici Adı',
        ManagerMailAddress:'Yönetici Mail Adresi',
        ManagerDepartment:'Yönetici Departman',
        ApprovalQueue:'Onay Sırası'
      },
      EXPENSETYPE: {
        Name: 'Adı',
        MaximumPrice: 'Maksimum Tutar',
        AddExpenseType: 'Masraf Türü Tanımla',
        UpdateExpenseType: 'Masraf Türü Güncelle',
        Unlimited:'Limitsiz',
        Vat:'KDV',
        IsDocument:'Döküman Zorunlu'
      },
      EXPENSE: {        
        Id: 'Masraf Id',
        ExpenseDate: 'Masraf Tarihi',
        PaymentType: 'Ödeme Tipi',
        Description: 'Açıklama',
        InstallmentCount: 'Taksit Adedi',
        ExpenseAmount: 'Masraf Tutarı',
        UserName: 'Personel Adı',
        ExpenseTypeName: 'Masraf Türü',
        FlowStateName: 'Durumu',
        CurrencyCode: 'Para Birimi',
        Invoice: 'Fatura/Fiş No',
        DocumentUrl: 'Döküman',
        AddDocument:'Döküman Ekle',
        AddExpense:'Masraf Tanımla',
        UpdateExpense: 'Masraf Güncelle',
        ExpenseTypeChoose:'Masraf Türü Seç',
        PaymentTypeChoose:'Ödeme Türü Seç',
        TotalExpense:'Toplam Masraf',        
        TotalExpenseAmount:'Toplam Alınan Masraf Tutarı',
        ApprovingCancelling:'Onaylayan/İptal Eden',
        ExpenseDetail:'Masraf Detayı',
        TransactionDate:'İşlem Tarihi',
        TotalAmount:'Toplam Tutar',
        Vat:'KDV'
      },
    },
    ERROR: {
      ERROR403: 'Yasak: Erişim reddedildi',
      ERROR403DECS: 'Sağladığınız kimlik bilgilerini kullanarak bu dizini veya sayfayı görüntüleme izniniz yok',
      ERROR404: 'Sayfa bulunamadı',
      ERROR404DECS: 'Aradığınız sayfa bulunamadı! Bir konu seçerek blog yayınınızı planlayın',
      ERROR500: 'Sistem hatası',
      ERROR500DECS: 'Bir şeyler yanlış gitti! Lütfen daha sonra tekrar deneyiniz'
    },
    primeng: {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      is: 'Is',
      isNot: 'Is not',
      before: 'Before',
      after: 'After',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: 'Temizle',
      apply: 'Uygula',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Evet',
      reject: 'Hayır',
      choose: 'Seç',
      upload: 'Yükle',
      cancel: 'İptal',
      dayNames: ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'],
      dayNamesShort: ['Paz', 'Ptz', 'Sal', 'Çar', 'Per', 'Cum', 'Cmt'],
      dayNamesMin: ['Pa', 'Pt', 'Sa', 'Ça', 'Pe', 'Cu', 'Cm'],
      monthNames: ['Ocak', 'Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim', 'Kasım', 'Aralık'],
      monthNamesShort: ['Oca', 'Şub', 'Mar', 'Nis', 'May', 'Haz', 'Tem', 'Ağu', 'Eyl', 'Eki', 'Kas', 'Ara'],
      dateFormat: 'dd/mm/yy',
      firstDayOfWeek: 1,
      today: 'Bugün',
      weekHeader: 'Wk',
      weak: 'Güçsüz',
      medium: 'Orta',
      strong: 'Güçlü',
      passwordPrompt: 'Enter a password',
      emptyMessage: 'Sonuç bulunamadı',
      emptyFilterMessage: 'Sonuç bulunamadı'
    }
  }
};