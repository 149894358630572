import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { AdvanceModel, ConfirmModel, RejectModel } from '../../models/advances/advance.model';

@Injectable({
  providedIn: 'root'
})
export class AdvanceService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/Advance`

  getAll() {    
    return this.base.get<AdvanceModel[]>(`${this.apiUrl}/GetAll`)     
  };

  getByUserId(tenantId:string,userId:string){
    return this.base.get<AdvanceModel[]>(`${this.apiUrl}/GetByUserId/${tenantId}/${userId}`)  
  }

  getByTenantId(tenantId:string,userId:string){    
    return this.base.get<AdvanceModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}/${userId}`)   
  }

  getPendingByTenantId(tenantId:string){    
    return this.base.get<AdvanceModel[]>(`${this.apiUrl}/GetPendingByTenantId/${tenantId}`) 
  }

  add(model: any): Observable<AdvanceModel> {
    return this.base.post(`${this.apiUrl}/Create`, model)    
  };

  remove(id: string) {
    return this.base.delete(`${this.apiUrl}/DeleteById/${id}`)     
  }

  confirm(model: ConfirmModel): Observable<boolean> {
    return this.base.post(`${this.apiUrl}/Confirm`, model)    
  };

  reject(model: RejectModel): Observable<boolean> {
    return this.base.post(`${this.apiUrl}/Reject`, model)     
  };

  getPdfById(id:string){ 
    return this.base.get(`${this.apiUrl}/GetPdfById/${id}`,{ responseType: 'text' as 'json' })    
  };

  /*getPdfById(id:string){ 
    return this.base.post(`${this.apiUrl}/GetPdfById/${id}`,{ responseType: 'text' as 'json' }) 
    .pipe(
      map((data: any) => {      
        return data;       
      }),
      catchError((ex: any) => {       
        return this.base.handleError(ex);
      }));
  }*/

}


