import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { ExpenseUserManagerModel } from '../../models/user-managers/expense-user-manager.model';

@Injectable({
  providedIn: 'root'
})
export class ExpenseUserManagerService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/ExpenseUserManager`
 
  getAll() {
    return this.base.get<ExpenseUserManagerModel[]>(`${this.apiUrl}/GetAll`)    
  };

  getByTenantId(tenantId: string) {
    return this.base.get<ExpenseUserManagerModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}`)    
  }

  getByUserId(userId:string="00000000-0000-0000-0000-000000000000") {
    return this.base.get<ExpenseUserManagerModel[]>(`${this.apiUrl}/GetByUserId/${userId}`)    
  }

  add(model: any): Observable<ExpenseUserManagerModel> {
    return this.base.post(`${this.apiUrl}/Create`, model)
  };

  remove(id: string) {
    return this.base.delete(`${this.apiUrl}/DeleteById/${id}`)    
  };

}
