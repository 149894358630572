import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { ExpenseDetailModel } from '../../models/expenses/expense-detail.model';

@Injectable({
  providedIn: 'root'
})
export class ExpenseHistoryService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/ExpenseHistory`
  
  getDetailByTenantId(expenseId:string){    
    return this.base.get<ExpenseDetailModel[]>(`${this.apiUrl}/GetDetailByTenantId/${expenseId}`)  
  };

}
