import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { DefaultCurrencyModel } from '../../models/default-currencies/default-currency-model';

@Injectable({
  providedIn: 'root'
})
export class DefaultCurrencyService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/DefaultCurrency`

  getAll() {    
    return this.base.get<DefaultCurrencyModel[]>(`${this.apiUrl}/GetAll`)     
  };
}
