import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AdvanceHistoryService } from './advance-histories/advance-history.service';
import { AdvanceDashboardService } from './advances/advance-dashboard.service';
import { AdvanceService } from './advances/advance.service';
import { ApiMainServicesComponent } from './api-main.services';
import { ExpenseHistoryService } from './expense-histories/expense-history.service';
import { ExpenseDashboardService } from './expenses/expense-dashboard.service';
import { ExpenseService } from './expenses/expense.service';
import { PublicAdvanceService } from './public-advances/public-advance.service';
import { PublicExpenseService } from './public-expenses/public-expense.service';
import { AdvanceReportService } from './reports/advance-report.service';
import { ExpenseReportService } from './reports/expense-report.service';

@NgModule({  
    declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule    
  ],
  providers: [ 
    ApiMainServicesComponent, 
    AdvanceService,
    ExpenseService,
    PublicAdvanceService,
    PublicExpenseService,
    AdvanceDashboardService,
    AdvanceHistoryService,
    ExpenseDashboardService,
    ExpenseHistoryService,
    AdvanceReportService,
    ExpenseReportService     
  ]  
})
export class ApiMainModule { }
