import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AuthModule } from './shared/modules/auth/auth.module';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateModule} from '@ngx-translate/core';
import { ApiAdminModule } from './admin/services/api-admin.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiMainModule } from './main/services/api-main.module';
import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,       
    BrowserAnimationsModule,
    LoadingBarModule,    
    TranslateModule.forRoot(),
    AppRoutingModule,
    AuthModule,    
    //environment.isPublic === true ? AuthModule  : [], 
    ApiAdminModule,
    ApiMainModule,    
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
  /*static injector: Injector;
    constructor(injector: Injector) {
        AppModule.injector = injector;
    }*/
 }
