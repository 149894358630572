// USA
export const locale = {
  lang: 'en',
  data: {
    TRANSLATOR: {
      SELECT: 'Select your language',
      LANGUAGE: 'Language'
    },
    MENU: {
      DASHBOARD: 'Dashboard',
      ADVANCE: 'Advance',
      EXPENSE: 'Expense',
      MANAGEMENTS: {
        MANAGEMENT: 'Management',
        USERS: {
          USERS: 'Users',
          CHILD: {
            USERLIST: 'User List',
            USERROLE: 'User Rol List',
            USERROLEDEFINITION:'User Rol Definition',
            USERAPPROVAL: 'User Approval Procedures'
          }
        },
        APPROVALPROCESSES: {
          APPROVALPROCESSES: 'Approval Processes',
          CHILD: {
            ADVANCE: 'Advance',
            EXPENSE: 'Expense'
          }
        },
        GENERAL: {
          GENERAL: 'General',
          CHILD: {
            CURRENCY: 'Currency',
            FLOWSTATE: 'Flow State',
            ADVANCETYPE: 'Advance Type',
            EXPENSETYPE: 'Expense Type'
          }
        }       
      },
      REPORTS:{
        REPORTS:'Reports',
        ADVANCE:'Advance',
        EXPENSE:'Expense',
        CHILD:{
          ADVANCES:'Advance Reports',
          EXPENSES:'Expense Reports'
        }
      }
    },
    GENERAL: {
      Save:'Save',
      Close:'Close',
      Yes:'Yes',
      No:'No',
      Choose: 'Choose..',
      Months: 'Months',
      Days: 'Days',
      RegistrationFailed: 'Registration Failed..!',
      RegistrationWarningInfo: 'Please Fill Required Fields..',
      ManagerWarningInfo: 'Assign At least One Administrator to the User..',
      LoadingFailed: 'Data could not be loaded..!',
      LoadingWait: 'Loading..',
      TotalAmount: 'Total Amount',
      New: 'New',
      Import: 'Import',
      RegistrationSuccessInfo: 'Record Added',
      UpdateSuccessInfo: 'Record Updated',
      Successful: 'Successful',
      DeleteWarningInfo: 'Are you sure you want to delete',
      DeleteSuccessfulInfo: 'Deleted',
      DeleteRequiredInfo: 'Specify the Id of the line to be deleted',
      DublicateRecordName: 'Already registered in your.',
      NoRecordsFound: 'No Records Found',
      Cash: 'Cash',
      Installment: 'Installment',
      Max500Character: 'Maximum 500 Characters',
      MinInstallmentCount: 'Enter at least 1 installment',
      UserIdRequired: 'User Id cannot be left blank',
      Accepted: 'Accepted',
      Waiting: 'Waiting',
      Cancalled: 'Cancalled',
      Rejected: 'Declined',
      CreatedBy: 'Created By',
      CreatedDate: 'Created Date',
      SearchFilter: 'Search...',
      MaximumTwoRecordInfo: 'You Can Choose Up To 2 Administrators..',
      ApproveInfo: 'Registration Approve',
      RejectInfo: 'Registration Rejected',
      Approve: 'Approve',
      Reject: 'Reject',
      Delete: 'Delete',
      Cancel: 'İptal Et',
      Print: 'Yazdır',
      ConfirmSuccessInfo: 'Confirmation Successful',
      ConfirmWarningInfo: 'No Streams Found to Approve',
      RejectSuccessInfo: 'Reject Successful',
      RejectWarningInfo: 'No Stream Found to Reject',
      Advance: 'Advance',
      Expense:'Expense',  
      Advances: 'Advances',
      Expenses: 'Expenses',
      CameraPermissions: 'Request Camera Permissions',
      ScanImageFile: 'Scan an Image File',
      AddQrCodeDocuments: 'Add Document with QR Code',
      About: 'About',
      Contact: 'Contact',
      Purchase: 'Purchase',
      SelectUser: 'Select User',
      SelectItem: 'Select Item..',
      Actions:'Actions',
      UserManagerPendingInfo:'User Has A Pending Stream. End the pending streams so that you can act as an administrator..',
      AdvanceManagerApprovalInfo:'You must have at least 1 manager to apply for an advance...',
      ExpenseManagerApprovalInfo:'You must have at least 1 manager to apply for an expense...',
      Detail:'Detail',
      AdvanceConfirmInfo:'Advances Pending My Approval',
      AdvanceAndExpense:'Advance And Expense',
      ChooseColumns:'Choose Columns',
      ColumnsSelected:'Columns Selected',
      DetailSearch: 'Detail Search...', 
      Search:'Search',
      Question:'Question',
      Date:'Date',
      StartDate:'Start Date',
      EndDate:'End Date',
      EmployeeName:'Employee Name',
      Employee:'Employee',
      AdvanceStatus:'Advance Status',
      ExpenseStatus:'Expense Status'
    },
    PAGES: {
      USERLIST: {
        Id: 'Id',
        TenantId: 'Tenant Id',
        Name: 'Name',
        Surname: 'Surname',
        DisplayName: 'Display Name',
        MailAddress: 'Mail Adress',
        Department: 'Department',
        JobTitle: 'Job Title',
        JoinDate: 'Join Date',
        IsDeleted: 'Is Deleted'
      },
      USERROLE: {
        UserRoleId: 'Id',
        UserName: 'User',
        RoleName: 'Role',
        CreatedBy: 'Created By',
        CreatedDate: 'Created Date',
        AssignRole: 'Assign Role',
        UpdateRole: 'Update Role'
      },
      FLOWSTATE: {
        Name: 'Name',
        Description: 'Description',
      },
      CURRENCY: {
        Code: 'Code',
        Name: 'Name',
        CurrencyType: 'Currency Type',
        AddCurrencyType: 'Para Birimi Tanımla',
        UpdateCurrencyType: 'Update Currency Type'
      },
      ADVANCETYPE: {
        Name: 'Name',
        MaximumPrice: 'Maximum Amount',
        AddAdvanceType: 'Add Advance Type',
        UpdateAdvanceType: 'Update Advance Type',
        Unlimited:'Unlimited'
      },
      ADVANCE: {
        Id: 'Advance Id',
        AdvanceDate: 'Advance Date',
        PaymentType: 'Payment Type',
        Description: 'Description',
        InstallmentCount: 'Installment Count',
        AdvanceAmount: 'Advance Amount',
        UserName: 'User Name',
        AdvanceTypeName: 'Advance Type',
        FlowStateName: 'State',
        CurrencyCode: 'Currency Type',
        AddAdvance: 'Add Advance',
        UpdateAdvance: 'Update Advance',
        AdvanceTypeChoose: 'Advance Type Choose',
        PaymentTypeChoose: 'Payment Type Choose',
        TotalAdvance:'Total Advance',
        TotalAdvanceAmount:'Total Advance Received Amount',
        ApprovingCancelling:'Approving/Cancelling',
        AdvanceDetail:'Advance Detail',
        TransactionDate:'Transaction Date'
      },
      USERMANAGER: {
        AssingManagers: 'Assign Managers To Users',
        UpdatingManagers:'Update Admin',
        AppointedManagers:'Appointed Managers',
        AssignedManagers:'Assigned Administrators',
        Users: 'User',
        Managers: 'Managers',
        UserDisplayName: 'User Name',
        UserMailAddress: 'User Mail Address',
        UserDepartment: 'User Department',
        ManagerDisplayName: 'Manager Name',
        ManagerMailAddress: 'Manager Mail Address',
        ManagerDepartment: 'Manager Department',
        ApprovalQueue: 'Approval Queue'
      },
      EXPENSETYPE: {
        Name: 'Adı',
        MaximumPrice: 'Maksimum Tutar',
        AddExpenseType: 'Add Expense Type',
        UpdateExpenseType: 'Update Expense Type',
        Unlimited:'Unlimited',
        Vat:'Vat',
        IsDocument:'Is Document'
      },
      EXPENSE: {
        Id: 'Expense Id',
        ExpenseDate: 'Expense Date',
        PaymentType: 'Payment Type',
        Description: 'Description',
        InstallmentCount: 'Installment Count',
        ExpenseAmount: 'Expense Amount',
        UserName: 'User Name',
        ExpenseTypeName: 'Expense Type',
        FlowStateName: 'State',
        CurrencyCode: 'Currency Type',
        Invoice: 'Invoice No',
        DocumentUrl: 'Document',
        AddDocument: 'Add Document',
        AddExpense: 'Add Expense',
        UpdateExpense: 'Update Expense',
        ExpenseTypeChoose: 'Expense Type Choose',
        PaymentTypeChoose: 'Payment Type Choose',
        TotalExpense:'Total Expense',
        TotalExpenseAmount:'Total Expense Received Amount',
        ApprovingCancelling:'Approving/Cancelling',
        ExpenseDetail:'Expense Detail',
        TransactionDate:'Transaction Date',
        TotalAmount:'Total Amount',
        Vat:'Vat'
      }
    }
  },
  ERROR: {
    ERROR403: 'Forbidden: Access is denied',
    ERROR403DECS: 'You do not have permission to view this directory or page using the credentials that you supplied',
    ERROR404: 'Page Not Found',
    ERROR404DECS: 'The page you looked not found! Plan your blog post by choosing a topic',
    ERROR500: 'System Error',
    ERROR500DECS: 'Something went wrong! Please try again later'
  },
  primeng: {
    startsWith: 'Starts with',
    contains: 'Contains',
    notContains: 'Not contains',
    endsWith: 'Ends with',
    equals: 'Equals',
    notEquals: 'Not equals',
    noFilter: 'No Filter',
    lt: 'Less than',
    lte: 'Less than or equal to',
    gt: 'Greater than',
    gte: 'Greater than or equal to',
    is: 'Is',
    isNot: 'Is not',
    before: 'Before',
    after: 'After',
    dateIs: 'Date is',
    dateIsNot: 'Date is not',
    dateBefore: 'Date is before',
    dateAfter: 'Date is after',
    clear: 'Clear',
    apply: 'Apply',
    matchAll: 'Match All',
    matchAny: 'Match Any',
    addRule: 'Add Rule',
    removeRule: 'Remove Rule',
    accept: 'Yes',
    reject: 'No',
    choose: 'Choose',
    upload: 'Upload',
    cancel: 'Cancel',
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dateFormat: 'dd/mm/yy',
    firstDayOfWeek: 0,
    today: 'Today',
    weekHeader: 'Wk',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Enter a password',
    emptyMessage: 'No results found',
    emptyFilterMessage: 'No results found'
  }
}; 
