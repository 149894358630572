import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmModel, RejectModel } from '../../models/expenses/expense.model';

@Injectable({
  providedIn: 'root'
})
export class PublicExpenseService {
  constructor(
    public http: HttpClient
  ) { }
  apiUri = `${environment.apiUri}/api`

  confirm(model: ConfirmModel) {
    return this.http.post(`${this.apiUri}/PublicExpense/Confirm`, model)
      .pipe(catchError(this.handleError));
  };

  reject(model: RejectModel) {
    return this.http.post(`${this.apiUri}/PublicExpense/Reject`, model)
      .pipe(catchError(this.handleError));
  };

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.log(`Backend returned code ${error.status}, body was: `, error.error);
      return throwError(() => new Error(`Backend returned code ${error.status}, body was: ${error.error}`));
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  };

}
