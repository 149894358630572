import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { ExpenseReportModel } from '../../models/reports/expense-report.model';

@Injectable({
  providedIn: 'root'
})
export class ExpenseReportService {
  constructor(private base: BaseHttpService) {}
  apiUrl = `${this.base.baseUri}/Report`;

  getByTenantId(tenantId: string) {
    return this.base.get<ExpenseReportModel[]>(
      `${this.apiUrl}/GetExpenseByTenantId/${tenantId}`
    );
  }

  getExpenseByCondition(model: any) {    
    return this.base.get<ExpenseReportModel[]>(
      `${this.apiUrl}/GetExpenseByCondition?tenantId=${model.TenantId}&&startDate=${model.StartDate??""}&&endDate=${model.EndDate??""}&&userId=${model.UserId??""}&&flowStateId=${model.FlowStateId??""}`
    );
  }

}
