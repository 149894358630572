import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { TranslationService } from './shared/modules/translation';
import { ThemeModeService } from './shared/_metronic/partials/layout/theme-mode-switcher/theme-mode.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as trLang } from 'src/assets/i18n/tr';
import { locale as enLang } from 'src/assets/i18n/en';
import { locale as aeLang } from 'src/assets/i18n/ae';
import { DOCUMENT } from '@angular/common';
@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent  implements OnInit {
  title:string=this.translate.instant('GENERAL.AdvanceAndExpense')
  constructor(    
    private translate: TranslateService,
    private translationService: TranslationService,
    private modeService: ThemeModeService,    
    @Inject(DOCUMENT) private document: Document
  ) {    
    // register translations
    this.translationService.loadTranslations(
      trLang,
      enLang,
      aeLang
    );
  }
 
  ngOnInit() {    
    this.modeService.init();    
    const htmlTag = this.document.getElementsByTagName("html")[0] as HTMLHtmlElement;
    htmlTag.dir = this.translationService.getSelectedLanguage() === "ae" ? "rtl" : "ltr";    
  }
}
