import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate,CanActivateChild {
    constructor(private authService: AuthService, private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {    
        const currentUser = this.authService.currentUserValue;               
        if (currentUser) { 
          //this.router.navigate(['dashboard']);         
          return true;
        }      
        this.authService.logout();
        return false;       
      };

      canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {      
        const currentUserRole = this.authService.currentUserRoleValue;               
        if (currentUserRole) { 
          //this.router.navigate(['dashboard']);         
          return true;
        }      
        this.authService.logout();
        return false;    
    }
     /* canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let url: string = state.url;
        return this.checkUserLogin(next, url);
      }*/

     
}