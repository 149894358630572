import { Component} from "@angular/core";
import { AdvanceHistoryService } from "./advance-histories/advance-history.service";
import { AdvanceDashboardService } from "./advances/advance-dashboard.service";
import { AdvanceService } from "./advances/advance.service";
import { ExpenseHistoryService } from "./expense-histories/expense-history.service";
import { ExpenseDashboardService } from "./expenses/expense-dashboard.service";
import { ExpenseService } from "./expenses/expense.service";
import { PublicAdvanceService } from "./public-advances/public-advance.service";
import { PublicExpenseService } from "./public-expenses/public-expense.service";
import { AdvanceReportService } from "./reports/advance-report.service";
import { ExpenseReportService } from "./reports/expense-report.service";

@Component({
    selector: 'api-main-services',
    template: `NO UI TO BE FOUND HERE!`
})
export  class ApiMainServicesComponent  {
    constructor(     
        public advanceService: AdvanceService,
        public expenseService:ExpenseService,
        public publicAdvanceService:PublicAdvanceService,
        public publicExpenseService:PublicExpenseService, 
        public advanceDashboardService:AdvanceDashboardService, 
        public advanceHistoryService:AdvanceHistoryService,        
        public expenseDashboardService:ExpenseDashboardService,
        public expenseHistoryService:ExpenseHistoryService,
        public advanceReportService:AdvanceReportService,
        public expenseReportService:ExpenseReportService              
    ){}
}
