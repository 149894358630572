import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { ExportSettingModel } from '../../models/export-settings/export-setting.model';

@Injectable({
  providedIn: 'root'
})
export class ExportSettingService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/ExportSetting`

  getByTenantId(tenantId: string) {    
    return this.base.get<ExportSettingModel[]>(`${this.apiUrl}/GetByTenantId/${tenantId}`)
  };

}
