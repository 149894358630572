import { Component} from "@angular/core";
import { AdvanceService } from "src/app/main/services/advances/advance.service";
import { ExpenseService } from "src/app/main/services/expenses/expense.service";
import { AdvanceTypeService } from "./advance-types/advance-type.service";
import { CurrencyService } from "./currencies/currency.service";
import { DefaultCurrencyService } from "./default-currencies/default-currency.service";
import { ExpenseTypeService } from "./expense-types/expense-type.service";
import { FlowStateService } from "./flowStates/flow-state.service";
import { RoleService } from "./roles/role.service";
import { AdvanceUserManagerService } from "./user-managers/advance-user-manager.service";
import { ExpenseUserManagerService } from "./user-managers/expense-user-manager.service";
import { UserRoleService } from "./user-roles/user-role.service";
import { UserService } from "./users/user.service";
import { ExportSettingService } from "./export-settings/export-setting.service";

@Component({
    selector: 'api-admin-services',
    template: `NO UI TO BE FOUND HERE!`
})
export  class ApiAdminServicesComponent  {
    constructor(
        public userService: UserService, 
        public userRoleService: UserRoleService,
        public roleService:RoleService,
        public currencyService:CurrencyService,
        public flowStateService:FlowStateService,        
        public advanceTypeService:AdvanceTypeService,
        public advanceUserManagerService: AdvanceUserManagerService,

        public expenseUserManagerService: ExpenseUserManagerService,
        public expenseTypeService:ExpenseTypeService,
        public defaultCurrencyService:DefaultCurrencyService,
        public advanceService: AdvanceService,
        public expenseService:ExpenseService,
        public exportSettingService:ExportSettingService
    ){}
}

