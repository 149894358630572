import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { AuthGuard } from './shared/modules/auth/guards/auth.guard';
import { RoleGuard } from './shared/modules/auth/guards/role.guard';

const routerOptions:ExtraOptions={
  scrollPositionRestoration:'enabled',
  anchorScrolling:'enabled'
  };

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./main/pages/public-advance/confirm-advance/confirm-advance.module').then((m) => m.ConfirmAdvanceModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./main/pages/public-advance/reject-advance/reject-advance.module').then((m) => m.RejectAdvanceModule)
  },

  {
    path: '',
    loadChildren: () =>
      import('./main/pages/public-expense/confirm-expense/confirm-expense.module').then((m) => m.ConfirmExpenseModule)
  },
  {
    path: '',
    loadChildren: () =>
      import('./main/pages/public-expense/reject-expense/reject-expense.module').then((m) => m.RejectExpenseModule)
  },

  {
    path: 'auth',    
    loadChildren: () =>    
      import('./shared/modules/auth/auth.module').then((m) => m.AuthModule),
  },  
  {
    path: '',
    canActivate: [AuthGuard],   
    loadChildren: () =>
      import('./shared/_metronic/layout/layout.module').then((m) => m.LayoutModule),
  }, 
 
  {
    path: 'error',
    loadChildren: () =>
      import('./shared/modules/errors/errors.module').then((m) => m.ErrorsModule),
  },
  { path: '**', redirectTo: 'error/404' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes,routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
