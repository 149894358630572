import { Injectable } from '@angular/core';
import { BaseHttpService } from 'src/app/shared/modules/auth/services/base-http.service';
import { RoleModel } from '../../models/roles/role.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/Role`

  getAll() {    
    return this.base.get<RoleModel[]>(`${this.apiUrl}/GetAll`)   
  };
}
