import { Inject, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError,  Observable, of, Subject, takeUntil } from 'rxjs';
import PeakAuth from 'peakauth';
import { AuthToken } from '../providers/auth.provider';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { UserModel } from '../models/user.model';
import { UserRoleModel } from '../models/user-role.model';
import PeakAuthMeModel from 'peakauth/lib/models/PeakAuthMeModel';
import { SessionStorageService } from './session-storage.service';
import { UserRoleService } from './user-role.service';
import { UsersService } from './users.service';
import { Router } from '@angular/router';

export type UserType = UserModel | undefined;
export type UserRoleType = UserRoleModel | undefined;
@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  private authUserStorage = `${environment.userDataKey}`;
  private authUserRoleStorage = `${environment.userRoleDataKey}`;
  isLoadingSubject: BehaviorSubject<boolean> | undefined;
  isLoading$: Observable<boolean> | undefined;
  private unsubscribe = new Subject<void>();

  currentUser$: Observable<UserType> | undefined;
  currentUserSubject: BehaviorSubject<UserType>;

  currentUserRole$: Observable<UserRoleType> | undefined;
  currentUserRoleSubject: BehaviorSubject<UserRoleType>;

  me: PeakAuthMeModel | undefined;

  constructor(
    @Inject(AuthToken) public auth: PeakAuth,
    //public tokenHelper: TokenHelper,
    private sessionStorageService: SessionStorageService,
    private userRoleService: UserRoleService,
    private userService: UsersService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentUserRoleSubject = new BehaviorSubject<UserRoleType>(undefined);
    this.currentUserRole$ = this.currentUserRoleSubject.asObservable();
  }
  returnUrl!: string;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  get currentUserRoleValue(): UserRoleType {
    return this.currentUserRoleSubject.value;
  }

  set currentUserRoleValue(userRole: UserRoleType) {
    this.currentUserRoleSubject.next(userRole);
  }

  async login() {
    //#region public url
    if (this.document.documentURI.includes('confirm-advance')) {
      return true;
    };
    if (this.document.documentURI.includes('reject-advance')) {
      return true;
    };
    if (this.document.documentURI.includes('confirm-expense')) {
      return true;
    };
    if (this.document.documentURI.includes('reject-expense')) {
      return true;
    };
    //#endregion 
    
    this.me = await this.auth.clients.me.getAsync();
    if (!this.me.user?.isActive) {
      this.logout();
      return of(false);
    }

    this.userService.getById(this.me.user?.id!)
      .pipe(
        takeUntil(this.unsubscribe),
        catchError((ex) => {
          this.isLoadingSubject?.next(false)
          //return of(ex)
          return ex;
        }))
      .subscribe({
        next: (result: any): any => {
          if (!result || result.IsDeleted) {
            this.router.navigate(['/error/403']);
            return of(false);
          }
          else {
            this.sessionStorageService.clearData();
            this.sessionStorageService.saveDataEncrypt(this.authUserStorage, JSON.stringify(result))
            this.currentUserSubject.next(result);

            this.userRoleService.getById(this.currentUserValue?.Id!)
              .pipe(
                takeUntil(this.unsubscribe),
                catchError((ex) => {
                  this.isLoadingSubject?.next(false);
                  //return of(ex)
                  return ex;                  
                }))
              .subscribe({
                next: (resultRole: any) => {
                  if (resultRole.length < 1) {
                    this.router.navigate(['/error/403']);
                    return of(false);
                  }
                  this.sessionStorageService.removeData(this.authUserRoleStorage);
                  this.sessionStorageService.saveDataEncrypt(this.authUserRoleStorage, JSON.stringify(resultRole));
                  this.currentUserRoleSubject.next(resultRole);

                  let locat = document.location.pathname//location.href;
                  history.replaceState(null, "", location.origin);
                  history.pushState(history.state, "", locat);
                  if (document.location.pathname === "/") {
                    history.pushState(history.state, "", "/dashboard");
                    return of(true);
                  }
                  return of(true);
                }

              });
          }
        }
      });
    return of(false)  
  }

  async logout(redirect: boolean = true) {
    await this.auth.clients.logout.postAsync();
    this.auth.resolver.cache.tokenResponse.remove();
    this.sessionStorageService.clearData();
    //this.tokenHelper.clearStorage();
    await this.auth.reauthAsync(false, redirect);
  }

  private to<T>(p: Promise<T>): Promise<Array<T | {} | null>> {
    return p.then(v => [null, v]).catch(err => [err]);
  }

  isLoggedIn(): boolean {
    let user = JSON.parse(this.sessionStorageService.getDataDecrypt(this.authUserStorage) ?? "")
    let userRole = this.sessionStorageService.getDataDecrypt(this.authUserRoleStorage) != "" ? JSON.parse(this.sessionStorageService.getDataDecrypt(this.authUserRoleStorage)) : null
    if (!user) {
      this.auth.resolver.cache.tokenResponse.remove();
      this.sessionStorageService.clearData();
      return false;
    }
    if (!userRole) {
      return false
    }
    return true;
  }

  public getRole(): UserRoleModel[] {
    let userRole = this.sessionStorageService.getDataDecrypt(this.authUserRoleStorage) != "" ? JSON.parse(this.sessionStorageService.getDataDecrypt(this.authUserRoleStorage)) : null
    if (!userRole) {
      return userRole;
    }
    this.currentUserRoleSubject.next(userRole);
    return userRole;
  }
  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
