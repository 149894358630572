import { Injectable } from '@angular/core';
import { UserRoleModel } from '../models/user-role.model';
import { BaseHttpService } from './base-http.service';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService {
  constructor(
    private base: BaseHttpService
  ) { }
  apiUrl = `${this.base.baseUri}/UserRole`

  getById(id: string) {
    return this.base.get<UserRoleModel[]>(`${this.apiUrl}/GetById?id=${id}`)
  };


}
